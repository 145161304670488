// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-aff-2022-js": () => import("./../../../src/pages/aff2022.js" /* webpackChunkName: "component---src-pages-aff-2022-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-demo-cgi-js": () => import("./../../../src/pages/demo/cgi.js" /* webpackChunkName: "component---src-pages-demo-cgi-js" */),
  "component---src-pages-demo-js": () => import("./../../../src/pages/demo.js" /* webpackChunkName: "component---src-pages-demo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ironcap-bountycontest-ct-js": () => import("./../../../src/pages/ironcap-bountycontest/ct.js" /* webpackChunkName: "component---src-pages-ironcap-bountycontest-ct-js" */),
  "component---src-pages-ironcap-bountycontest-en-js": () => import("./../../../src/pages/ironcap-bountycontest/en.js" /* webpackChunkName: "component---src-pages-ironcap-bountycontest-en-js" */),
  "component---src-pages-ironcap-bountycontest-js": () => import("./../../../src/pages/ironcap-bountycontest.js" /* webpackChunkName: "component---src-pages-ironcap-bountycontest-js" */),
  "component---src-pages-ironcap-bountycontest-wechat-callback-js": () => import("./../../../src/pages/ironcap-bountycontest/wechat-callback.js" /* webpackChunkName: "component---src-pages-ironcap-bountycontest-wechat-callback-js" */),
  "component---src-pages-ironcap-toolkits-about-js": () => import("./../../../src/pages/ironcap-toolkits/about.js" /* webpackChunkName: "component---src-pages-ironcap-toolkits-about-js" */),
  "component---src-pages-ironcap-toolkits-faq-js": () => import("./../../../src/pages/ironcap-toolkits/faq.js" /* webpackChunkName: "component---src-pages-ironcap-toolkits-faq-js" */),
  "component---src-pages-ironcap-toolkits-index-js": () => import("./../../../src/pages/ironcap-toolkits/index.js" /* webpackChunkName: "component---src-pages-ironcap-toolkits-index-js" */),
  "component---src-pages-ironcap-toolkits-support-js": () => import("./../../../src/pages/ironcap-toolkits/support.js" /* webpackChunkName: "component---src-pages-ironcap-toolkits-support-js" */),
  "component---src-pages-ironcap-toolkits-videos-js": () => import("./../../../src/pages/ironcap-toolkits/videos.js" /* webpackChunkName: "component---src-pages-ironcap-toolkits-videos-js" */),
  "component---src-pages-ironcap-toolkits-white-papers-use-cases-js": () => import("./../../../src/pages/ironcap-toolkits/white-papers-use-cases.js" /* webpackChunkName: "component---src-pages-ironcap-toolkits-white-papers-use-cases-js" */),
  "component---src-pages-ironcap-x-about-js": () => import("./../../../src/pages/ironcap-x/about.js" /* webpackChunkName: "component---src-pages-ironcap-x-about-js" */),
  "component---src-pages-ironcap-x-business-js": () => import("./../../../src/pages/ironcap-x/business.js" /* webpackChunkName: "component---src-pages-ironcap-x-business-js" */),
  "component---src-pages-ironcap-x-faq-js": () => import("./../../../src/pages/ironcap-x/faq.js" /* webpackChunkName: "component---src-pages-ironcap-x-faq-js" */),
  "component---src-pages-ironcap-x-index-js": () => import("./../../../src/pages/ironcap-x/index.js" /* webpackChunkName: "component---src-pages-ironcap-x-index-js" */),
  "component---src-pages-ironcap-x-personal-js": () => import("./../../../src/pages/ironcap-x/personal.js" /* webpackChunkName: "component---src-pages-ironcap-x-personal-js" */),
  "component---src-pages-ironcap-x-support-js": () => import("./../../../src/pages/ironcap-x/support.js" /* webpackChunkName: "component---src-pages-ironcap-x-support-js" */),
  "component---src-pages-ironcap-x-versions-latest-en-index-js": () => import("./../../../src/pages/ironcap-x/versions/latest/en/index.js" /* webpackChunkName: "component---src-pages-ironcap-x-versions-latest-en-index-js" */),
  "component---src-pages-ironcap-x-versions-v-1-0-en-index-js": () => import("./../../../src/pages/ironcap-x/versions/v1.0/en/index.js" /* webpackChunkName: "component---src-pages-ironcap-x-versions-v-1-0-en-index-js" */),
  "component---src-pages-ironcap-x-versions-v-1-1-en-index-js": () => import("./../../../src/pages/ironcap-x/versions/v1.1/en/index.js" /* webpackChunkName: "component---src-pages-ironcap-x-versions-v-1-1-en-index-js" */),
  "component---src-pages-ironcap-x-versions-v-1-2-en-index-js": () => import("./../../../src/pages/ironcap-x/versions/v1.2/en/index.js" /* webpackChunkName: "component---src-pages-ironcap-x-versions-v-1-2-en-index-js" */),
  "component---src-pages-ironcap-x-versions-v-2-0-en-index-js": () => import("./../../../src/pages/ironcap-x/versions/v2.0/en/index.js" /* webpackChunkName: "component---src-pages-ironcap-x-versions-v-2-0-en-index-js" */),
  "component---src-pages-ironcap-x-videos-js": () => import("./../../../src/pages/ironcap-x/videos.js" /* webpackChunkName: "component---src-pages-ironcap-x-videos-js" */),
  "component---src-pages-ironcap-x-welcome-js": () => import("./../../../src/pages/ironcap-x/welcome.js" /* webpackChunkName: "component---src-pages-ironcap-x-welcome-js" */),
  "component---src-pages-ironcap-x-white-papers-use-cases-js": () => import("./../../../src/pages/ironcap-x/white-papers-use-cases.js" /* webpackChunkName: "component---src-pages-ironcap-x-white-papers-use-cases-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-page-not-found-js": () => import("./../../../src/pages/page-not-found.js" /* webpackChunkName: "component---src-pages-page-not-found-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-white-papers-use-cases-js": () => import("./../../../src/pages/white-papers-use-cases.js" /* webpackChunkName: "component---src-pages-white-papers-use-cases-js" */)
}

